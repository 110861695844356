import React, { useEffect, useState } from "react";
import "./card.css";
import Images from "../../common/Images/Images";
import { useAuthContext } from "../../context/AuthContext";
import { Link, useLocation, useParams } from "react-router-dom";
import { AiOutlineFileAdd } from "react-icons/ai";
import { BASE_URL } from "../../utils/apiConfig";
import axios from "axios";

// Table
import table1 from "../../assets/Cards/table/1.jpg";
import table2 from "../../assets/Cards/table/2.jpg";
import table3 from "../../assets/Cards/table/3.jpg";
import table4 from "../../assets/Cards/table/4.jpg";
import table5 from "../../assets/Cards/table/5.jpg";
import footerTable from "../../assets/Cards/table/footer.jpg";

//Tablo
import tablo1 from "../../assets/Cards/tablo/1.jpg";
import tablo2 from "../../assets/Cards/tablo/2.jpg";
import tablo3 from "../../assets/Cards/tablo/3.jpg";
import tablo4 from "../../assets/Cards/tablo/4.jpg";
import tablo5 from "../../assets/Cards/tablo/5.jpg";
import tablo6 from "../../assets/Cards/tablo/6.jpg";
import tablo7 from "../../assets/Cards/tablo/7.jpg";
import tablo8 from "../../assets/Cards/tablo/8.jpg";
import footerTablo from "../../assets/Cards/tablo/footer.jpg";

//Cards
import card1 from "../../assets/Cards/cards/1.jpg";
import card2 from "../../assets/Cards/cards/2.jpg";
import card3 from "../../assets/Cards/cards/3.jpg";
import footerCard from "../../assets/Cards/cards/footer.jpg";

const Card = () => {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const [cards, setCards] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [tables, setTables] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const [res1, res2, res3] = await Promise.all([
          axios.get(`${BASE_URL}/images/invitations`),
          axios.get(`${BASE_URL}/images/tables`),
          axios.get(`${BASE_URL}/images/cards`),
        ]);

        setInvitations(res1.data);
        setTables(res2.data);
        setCards(res3.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInvitations();
  }, []);

  const tabloImgs = [
    {
      img: footerTablo,
    },
    {
      img: tablo1,
    },
    {
      img: tablo2,
    },
    {
      img: tablo3,
    },
    {
      img: tablo4,
    },
    {
      img: tablo5,
    },
    {
      img: tablo6,
    },
    {
      img: tablo7,
    },
    {
      img: tablo8,
    },
  ];

  const tableImgs = [
    {
      img: footerTable,
    },
    {
      img: table1,
    },
    {
      img: table2,
    },
    {
      img: table3,
    },
    {
      img: table4,
    },
    {
      img: table5,
    },
  ];

  const cardsImgs = [
    {
      img: footerCard,
    },
    {
      img: card1,
    },
    {
      img: card2,
    },
    {
      img: card3,
    },
  ];

  return (
    <section className="container">
      {user.email && (
        <div className="hidden_content">
          <Link to={`${pathname}/add`}>
            <button className="hidden__btn">
              <AiOutlineFileAdd />
            </button>
          </Link>
        </div>
      )}

      <div className="card__header">
        <div className="line"></div>
        <div className="text">
          Покани за гости, картички, настанително табло
        </div>
        <div className="line"></div>
      </div>

      <div className="card__body">
        <div className="card__items">
          <div className="card__img">
            {/* <img src={mainTable} alt="" /> */}
          </div>

          <div className="card__text">
            <p>
              Агенция за украса “Вълшебно цвете” Ви предлага покани за Вашите
              гости. Може да изберете от готовите ни модели или да направим
              индивидуален модел в зависимост от темата и цветовете на
              определения повод.
            </p>
          </div>
        </div>

        <div className="card__items">
          <div className="card__img">
            {/* <img src={mainTablo} alt="" /> */}
          </div>

          <div className="card__text">
            <p>
              За ваше удобство и на вашите гости , ние предлагаме също
              изработване на картички с имена на гости за разпределение по маси.
              Те ще бъдат изработени в цвета и темата на вашата украса.
            </p>
          </div>
        </div>

        <div className="card__items">
          <div className="card__img">{/* <img src={mainCard} alt="" /> */}</div>

          <div className="card__text">
            <p>
              За добрата организация на събитието може да поръчате и
              разпределителна табела с имената на гостите. Тя също може да бъде
              изработена като бъде съобразена с тематиката на събитието. Това ще
              бъде вашето табло за настаняване на гостите в ресторанта.
            </p>
          </div>
        </div>

        <div className="card__items">
          <div className="card__text">
            <p>
              Поканите за гости , картичките с имена за масите и настанителните
              табла могат да бъдат изработени по индивидуален проект. Разбира се
              , може да се възползвате и от нашите готови предложения:
            </p>
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div className="card__footer__container gallery">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <Images galleryImg={invitations[0].images} />
              <div className="gallery__text">
                <p>{invitations[0].title}</p>
              </div>
              {user.email && (
                <div className="gallery__btns">
                  <Link>
                    <button>Edit</button>
                  </Link>
                  <button>Delete</button>
                </div>
              )}
            </>
          )}
        </div>

        <div className="card__footer__container gallery">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <Images galleryImg={tables[0].images} />
              <div className="gallery__text">
                <p>{tables[0].title}</p>
              </div>
              {user.email && (
                <div className="gallery__btns">
                  <Link>
                    <button>Edit</button>
                  </Link>
                  <button>Delete</button>
                </div>
              )}
            </>
          )}
        </div>

        <div className="card__footer__container gallery">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <Images galleryImg={cards[0].images} />
              <div className="gallery__text">
                <p>{cards[0].title}</p>
              </div>
              {user.email && (
                <div className="gallery__btns">
                  <Link>
                    <button>Edit</button>
                  </Link>
                  <button>Delete</button>
                </div>
              )}
            </>
          )}
        </div>

        {/* <div className="card__footer__container gallery">
          <Images galleryImg={tableImgs} />
          <div className="gallery__text">
            <p>Картички с имена</p>
          </div>
          {user.email && (
            <div className="gallery__btns">
              <Link>
                <button>Edit</button>
              </Link>
              <button>Delete</button>
            </div>
          )}
        </div>

        <div className="card__footer__container gallery">
          <Images galleryImg={tabloImgs} />
          <div className="gallery__text">
            <p>Настанителни табели</p>
          </div>
          {user.email && (
            <div className="gallery__btns">
              <Link>
                <button>Edit</button>
              </Link>
              <button>Delete</button>
            </div>
          )}
        </div> */}
      </div>
    </section>
  );
};

export default Card;
